import { BaseService, Service } from "/@/cool";
@Service("model")
class Model extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["info"] = prefix + "info";
		this.permission["list"] = prefix + "list";
		this.permission["page"] = prefix + "page";
		this.permission["delete"] = prefix + "delete";
		this.permission["glb"] = prefix + "glb";
		this.permission["saveAngle"] = prefix + "saveAngle";
		this.permission["angle"] = prefix + "angle";
		this.permission["url"] = prefix + "url";
		this.permission["photo"] = prefix + "photo";
	}

	//下单
	glb(data: {}) {
		return this.request({
			url: "/glb",
			method: "POST",
			data
		});
	}

	// 角度
	angle(data: {}) {
		return this.request({
			url: "/angle",
			method: "POST",
			data
		});
	}

	// 建模
	modelingMake(params: any) {
		return this.request({
			url: "/submitModelingByShop",
			method: "POST",
			data: { ...params }
		});
	}
}
export default Model;
