import { BaseService, Service } from "/@/cool";
@Service("reprint")
class reprint extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["details"] = prefix + "details";
	}

	details(params: any) {
		return this.request({
			url: "/details",
			method: "get",
			params
		});
	}
}
export default reprint;
