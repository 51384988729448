import { BaseService, Service } from "/@/cool";
@Service("order")
class Order extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["makeOrderCheck"] = prefix + "makeOrderCheck";
		this.permission["makeOrderV2"] = prefix + "makeOrderV2";
		this.permission["makeQueueOrderV2"] = prefix + "makeQueueOrderV2";
		this.permission["goods"] = prefix + "goods";
		this.permission["goodsV2"] = prefix + "goodsV2";
		this.permission["makeAddPrintOrderV2"] = prefix + "makeAddPrintOrderV2";
		this.permission["info"] = prefix + "info";
		this.permission["goodsV2List"] = prefix + "goodsV2List";
		this.permission["perShopOrderDetail"] = prefix + "perShopOrderDetail";
		this.permission["checkShake"] = prefix + "checkShake";
		this.permission["updatePicProcessing"] = prefix + "updatePicProcessing";
		this.permission["shopTicketInfo"] = prefix + "shopTicketInfo";
	}

	makeOrderCheck(params: any) {
		return this.request({
			url: "/makeOrderCheck",
			method: "POST",
			data: {
				...params
			}
		});
	}

	updatePicProcessing(params: any) {
		return this.request({
			url: "/updatePicProcessing",
			method: "GET",
			data: {
				...params
			}
		});
	}

	makeOrderV2(params: any) {
		return this.request({
			url: "/makeOrderV2",
			method: "POST",
			data: {
				...params
			}
		});
	}

	makeQueueOrderV2(params: any) {
		return this.request({
			url: "/makeQueueOrderV2",
			method: "POST",
			data: {
				...params
			}
		});
	}

	// 商品
	goods(params: any) {
		return this.request({
			url: "/goods",
			method: "POST",
			data: {
				...params
			}
		});
	}
	// 商品
	goodsV2(params: any) {
		return this.request({
			url: "/goodsV2",
			method: "POST",
			data: {
				...params
			}
		});
	}

	makeAddPrintOrderV2(params: any) {
		return this.request({
			url: "/makeAddPrintOrderV2",
			method: "POST",
			data: {
				...params
			}
		});
	}
	GoodsV2List(params:any){
		return this.request({
			url: "/goodsV2List",
			method: "POST",
			data: {
				...params
			}
		});
	}
	perShopOrderDetail(data:any){
        return this.request({
            url: "/perShopOrderDetail",
            method: "POST",
            data
        });
    }

	// 抖动检查
	checkShake(data: {}) {
		return this.request({
			url: "/checkShake",
			method: "POST",
			data: {
				...data
			}
		});
	}

	shopTicketInfo(params: any) {
		return this.request({
			url: "/printTicket",
			method: "get",
			params
		});
	}
}
export default Order;
