import { BaseService, Service } from "/@/cool";
@Service("cart")
class Cart extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["remove"] = prefix + "remove";
		this.permission["create"] = prefix + "create";
		this.permission["list"] = prefix + "list";
		this.permission["coupon"] = prefix + "coupon";
	}

	create(params: any) {
		return this.request({
			url: "/create",
			method: "POST",
			data: params
		});
	}

	remove(params: any) {
		return this.request({
			url: "/remove",
			method: "POST",
			data: params
		});
	}
	coupon(params: any) {
		return this.request({
			url: "/coupon",
			method: "POST",
			data: params
		});
	}
}
export default Cart;
