import { BaseService, Service } from "/@/cool";
@Service("withdraw")
class Withdraw extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["total"] = prefix + "total";
	}
	// 统计
	total(data: any) {
		return this.request({
			url: "/total",
			method: "POST",
			data
		});
	}
}
export default Withdraw;
